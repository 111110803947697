import React from 'react';
import { Modal } from '@mui/material';
import PlanDetailsTableEbToolkit from "../PlanDetailsTableEbToolkit";

const PlanDetailsModalEbToolkit = ({ open, onClose, quote }) => {
    if (!quote) return null;

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="plan-details-modal"
            aria-describedby="plan-details-description"
        >
            <PlanDetailsTableEbToolkit quote={quote} containerStyle={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'min(90%, 600px)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 2,
                p: 4,
                maxHeight: '90vh',
                overflowY: 'auto'
            }}/>
        </Modal>
    );
};

export default PlanDetailsModalEbToolkit;