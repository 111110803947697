// QuoteFiltersHSA.js
import React from 'react';
import {TextField, MenuItem, Slider, Typography, Box} from '@mui/material';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import MetalDropdown from "../MetalDropdown";
import PlanTypeDropdown from "../PlanTypeDropdown";
import IsHsaEligibleDropdown from "../IsHsaEligibleDropdown";

const QuoteFiltersHSA = ({ quotes, filters, onFilterChange }) => {
    const { translate } = useCustomTranslation();

    // Collect the unique carriers, metals, and plan types.
    let carriers = new Set();
    let metals = new Set();
    let planTypes = new Set();
    for (const quote of quotes) {
        carriers.add(quote.carrierName);
        metals.add(quote.webLevel);
        planTypes.add(quote.typePlan);
    }

    carriers = [...carriers];
    metals = [...metals];
    planTypes = [...planTypes];

    const handleCarrierChange = (event) => {
        onFilterChange({ ...filters, selectedCarrier: event.target.value });
    };

    const handleRateRangeChange = (event, newValue) => {
        onFilterChange({ ...filters, rateRange: newValue });
    };

    const handleMetalChange = (event) => {
        onFilterChange({ ...filters, selectedMetalLevel: event.target.value });
    };

    const handlePlanTypeChange = (event) => {
        onFilterChange({ ...filters, selectedPlanType: event.target.value });
    };

    const handleIsHsaEligibleChange = (event) => {
        onFilterChange({ ...filters, isHsaEligible: event.target.value });
    }

    return (
        <>
            <TextField
                label="Filter by Carrier"
                select
                fullWidth
                value={filters.selectedCarrier}
                onChange={handleCarrierChange}
                sx={{ mb: 2 }}
            >
                <MenuItem value="">{translate('All Carriers')}</MenuItem>
                {carriers.map((carrier, index) => (
                    <MenuItem key={index} value={carrier}>
                        {carrier}
                    </MenuItem>
                ))}
            </TextField>

            <Typography gutterBottom>{translate('Filter by Monthly Cost')}</Typography>
            <Slider
                value={filters.rateRange}
                onChange={handleRateRangeChange}
                valueLabelDisplay="auto"
                min={0}
                max={Math.max(...quotes.map(quote => quote.censusTotal))}
                sx={{ mb: 2 }}
            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '2rem' }}>
                <Box sx={{ flexGrow: 2 }}>
                    <MetalDropdown
                        selectedMetalLevel = {filters.selectedMetalLevel}
                        metals = {metals}
                        onChange={handleMetalChange}
                    />
                </Box>


                <Box sx={{ flexGrow: 2 }}>
                    <PlanTypeDropdown
                        selectedPlanType = {filters.selectedPlanType}
                        planTypes = {planTypes}
                        onChange={handlePlanTypeChange}
                    />
                </Box>

                <Box sx={{ flexGrow: 2 }}>
                    <IsHsaEligibleDropdown
                        selectedOption = {filters.isHsaEligible}
                        onChange={handleIsHsaEligibleChange}
                    />
                </Box>
            </Box>
        </>
    );
};

export default QuoteFiltersHSA;
