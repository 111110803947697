import {useLazyGetDocumentsByPlanIdQuery} from "../reducers/enrollmentApiSlice";
import {useState} from "react";

export const useGetLocalPlanSummaryDocuments = (planId) => {
    const [getDocumentsByPlanId, { isFetching, isError }] = useLazyGetDocumentsByPlanIdQuery();
    const [documents, setDocuments] = useState([]);

    const fetchDocuments = async () => {
        if (!planId) {
            console.error('planId is undefined or null.');
            return;
        }
        try {
            const result = await getDocumentsByPlanId(planId);
            if (result.data) {
                setDocuments(result.data);
            } else {
                console.error('No data returned for the document.');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    return {
        fetchDocuments,
        documents,
        isFetching,
        isError,
    }
};