import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {useTheme} from "@mui/material/styles";

const PlanTypeDropdown = ({planTypes, selectedPlanType = '', onChange}) => {
    useTheme();
    const { translate } = useCustomTranslation();

    return (
        <FormControl sx={{ minWidth: '200px', width: '100%' }}>
            <InputLabel>{translate('Select Plan Type')}</InputLabel>
            <Select
                value={selectedPlanType}
                onChange={onChange}
                label={translate('Select Plan Type')}
            >
                <MenuItem key={'All Plan Types'} value={''}>
                    {translate('All Plan Types')}
                </MenuItem>
                {planTypes.map((planType) => (
                    <MenuItem key={planType} value={planType}>
                        {planType}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PlanTypeDropdown;