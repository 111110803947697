import {Button, useTheme} from "@mui/material";
import {gowellColors} from "../../themes/palettes/gowellColors";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";

const PlanSelectionCompareButton = ({handleAddToCompareList, quote, isSelectedForComparison}) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();


    return (
        <Button
            variant="outlined"
            size="small"
            onClick={() => handleAddToCompareList(quote)}
            sx={{
                marginBottom: 2,
                borderColor: isSelectedForComparison ? gowellColors.white : gowellColors.grey.dark,
                color: isSelectedForComparison ? gowellColors.blue.base : gowellColors.white,
                backgroundColor: isSelectedForComparison ? gowellColors.white : gowellColors.grey.dark,
                '&:hover': {
                    backgroundColor: isSelectedForComparison ? gowellColors.grey.light : gowellColors.grey.darker,
                    borderColor: isSelectedForComparison ? gowellColors.grey.light : gowellColors.grey.darker,
                },
                minWidth: '150px'
            }}
        >
            {isSelectedForComparison ? translate('Remove from Compare') : translate('Compare')}
        </Button>
    );
};

export default PlanSelectionCompareButton;