import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import HighlightedPlan from './HighlightedPlan';
import useCustomTranslation from '../../hooks/useCustomTranslation';

const PlansCarousel = ({ plans, selectedPlans, onPlanSelect, employerCode }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            // Reinitialize Swiper when selectedPlans change
            swiperRef.current.swiper.update();
        }
    }, [selectedPlans]);

    const handleSlideChange = (swiper) => {
        // Handle slide change if needed
    };

    const handleNavigationClick = (direction) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            direction === 'next'
                ? swiperRef.current.swiper.slideNext()
                : swiperRef.current.swiper.slidePrev();
        }
    };

    const isSelected = (planId) => {
        return selectedPlans.some((plan) => plan.planId === planId && plan.isActive);
    };

    const getPlanWithActiveStatus = (plan) => {
        const selectedPlan = selectedPlans.find(p => p.planId === plan.planId);
        return selectedPlan ? { ...plan, isActive: selectedPlan.isActive } : { ...plan, isActive: false };
    };

    if (!plans || plans.length === 0) {
        return (
            <Typography variant="body1" color="text.secondary">
                {translate('no_plans_available')}
            </Typography>
        );
    }

    return (
        <Box sx={{ position: 'relative', padding: theme.spacing(2) }}>
            <Swiper
                modules={[Pagination, A11y]}
                spaceBetween={theme.spacing(6)}
                slidesPerView={1}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                pagination={{ clickable: true }}
                onSlideChange={handleSlideChange}
                ref={swiperRef}
                a11y={{
                    prevSlideMessage: translate('previous_plan'),
                    nextSlideMessage: translate('next_plan'),
                    firstSlideMessage: translate('first_plan'),
                    lastSlideMessage: translate('last_plan'),
                }}
            >
                {plans.map((plan) => (
                    <SwiperSlide key={plan.planId}>
                        <HighlightedPlan
                            plan={getPlanWithActiveStatus(plan)}
                            isSelected={isSelected(plan.planId)}
                            onPlanSelect={onPlanSelect}
                            employerCode={employerCode}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <IconButton
                className="swiper-button-prev"
                onClick={() => handleNavigationClick('prev')}
                aria-label={translate('previous_plan')}
                sx={{
                    position: 'absolute',
                    left: theme.spacing(1),
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: theme.palette.background.paper,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }}
            >
                <ChevronLeft />
            </IconButton>
            <IconButton
                className="swiper-button-next"
                onClick={() => handleNavigationClick('next')}
                aria-label={translate('next_plan')}
                sx={{
                    position: 'absolute',
                    right: theme.spacing(1),
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                    backgroundColor: theme.palette.background.paper,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                }}
            >
                <ChevronRight />
            </IconButton>
        </Box>
    );
};

PlansCarousel.propTypes = {
    plans: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedPlans: PropTypes.arrayOf(PropTypes.shape({
        planId: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
    })).isRequired,
    onPlanSelect: PropTypes.func.isRequired,
    employerCode: PropTypes.string.isRequired,
};

export default PlansCarousel;