export const censusDownloadEndpoints = (builder) => ({
    // Endpoint for exporting census data to CSV
    exportCTXCensusData: builder.query({
        query: () => ({
            url: '/CensusDownload/export',
            method: 'GET',
        }),
        transformResponse: (response) => {
            return response ?? null;
        },
    }),
    // Endpoint for exporting census data to CSV by employer code
    exportCensusDataByEmployer: builder.query({
        query: (employerCode) => ({
            url: `api/CensusDownload/export-by-employer?employerCode=${encodeURIComponent(employerCode)}`,
            method: 'GET',
            // Remove responseHandler if the API returns JSON by default
        }),
        transformResponse: (response, meta) => {
            console.log('transformResponse - Response:', response);
            console.log('transformResponse - Meta:', meta);

            // Handle unauthorized responses
            if (meta?.response?.status === 401) {
                console.error('Unauthorized request');
                return { error: 'Unauthorized' };
            }

            // Handle empty responses
            if (!response) {
                console.error('Empty response from API');
                return { error: 'No data received' };
            }

            // Map the API's 'base64Csv' to 'Base64Csv'
            if (typeof response === 'object' && response.base64Csv) {
                return { Base64Csv: response.base64Csv };
            }

            // Handle unexpected response formats
            console.error('Unexpected response format:', response);
            return { error: 'Unexpected response format' };
        },
    }),

    // Endpoint for exporting census data to CSV by employer code and enrollment date
    exportCensusDataByEmployerAndDate: builder.query({
        query: ({ employerCode, enrollmentDate }) => ({
            url: `api/CensusDownload/export-by-employer-and-date?employerCode=${encodeURIComponent(employerCode)}&enrollmentDate=${encodeURIComponent(enrollmentDate)}`,
            method: 'GET',
        }),
        transformResponse: (response, meta) => {
            if (meta?.response?.status === 401) {
                return { error: 'Unauthorized' };
            }
            if (!response) {
                return { error: 'No data received' };
            }
            if (typeof response === 'object' && response.base64Csv) {
                return { Base64Csv: response.base64Csv };
            }
            return { error: 'Unexpected response format' };
        },
    }),
});