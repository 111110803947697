import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { useGetCompaniesByEmployerCodeQuery } from '../../reducers/enrollmentApiSlice';
import LoadingSpinner from '../LoadingSpinner';

const CompanyDropdown = ({ employerCode, onSelectionChange, preselectedCompanyId }) => {
    const [selectedCompanyId, setSelectedCompanyId] = useState(preselectedCompanyId || '');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { data: companies, error, isLoading } = useGetCompaniesByEmployerCodeQuery({ employerCode });

    useEffect(() => {
        // If a company is preselected, pass its details to the parent and set our internal state.
        if (preselectedCompanyId && companies) {
            const preselectedCompany = companies.find(
                (company) => company.companyId === parseInt(preselectedCompanyId)
            );
            if (preselectedCompany) {
                onSelectionChange(preselectedCompany);
                setSelectedCompanyId(preselectedCompany.companyId);
            }
        }
    }, [preselectedCompanyId, companies, onSelectionChange]);

    const handleDropdownChange = (event) => {
        const newSelectedCompanyId = event.target.value;
        setSelectedCompanyId(newSelectedCompanyId);

        // Find the selected company details
        if (companies) {
            const selectedCompany = companies.find(
                (company) => company.companyId === parseInt(newSelectedCompanyId)
            );
            if (selectedCompany) {
                onSelectionChange(selectedCompany);
            }
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {isLoading && <LoadingSpinner />}
                {error && (
                    <Typography color="error" role="alert">
                        Failed to load companies.
                    </Typography>
                )}
                {companies && companies.length > 0 && (
                    <FormControl fullWidth>
                        <InputLabel id="company-select-label">Select Company</InputLabel>
                        <Select
                            labelId="company-select-label"
                            id="company-select"
                            value={selectedCompanyId}
                            onChange={handleDropdownChange}
                            label="Select Company"
                            sx={{ minWidth: isMobile ? '100%' : 300 }}
                        >
                            {companies.map((company) => (
                                <MenuItem key={company.companyId} value={company.companyId.toString()}>
                                    {company.companyName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>
        </Container>
    );
};

CompanyDropdown.propTypes = {
    employerCode: PropTypes.string.isRequired, // Employer code passed to the query
    onSelectionChange: PropTypes.func.isRequired, // Callback to handle selected company
    preselectedCompanyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Preselected company ID
};

CompanyDropdown.defaultProps = {
    preselectedCompanyId: '', // Default value for preselected company ID
};

export default CompanyDropdown;
