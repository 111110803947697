import React, { useState, useCallback } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    useTheme
} from '@mui/material';
import { useLazyGetPayrollReportByYearAndEmployerCodeQuery } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import EmployerCheckboxList from '../Employer/EmployerCheckboxList';
import AuthTokenService from '../../services/AuthTokenService';

const ReportingPage = () => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();

    const currentYear = new Date().getFullYear();
    const [selectedEmployers, setSelectedEmployers] = useState([]);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [currentEmployer, setCurrentEmployer] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [errors, setErrors] = useState([]);

    const [triggerGetPayrollReport] = useLazyGetPayrollReportByYearAndEmployerCodeQuery();

    const handleEmployerSelect = (employers) => {
        setSelectedEmployers(employers);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleGenerateReport = async () => {
        setIsFetching(true);
        setProgress(0);
        setErrors([]);

        for (let i = 0; i < selectedEmployers.length; i++) {
            const employer = selectedEmployers[i];
            setCurrentEmployer(employer);

            try {
                const response = await triggerGetPayrollReport({
                    year: selectedYear,
                    employerCode: employer.employerCode,
                }).unwrap();

                downloadCsv(response, employer.employerCode);
            } catch (error) {
                setErrors((prevErrors) => [
                    ...prevErrors,
                    { employer: employer.employerName, error: error.message || translate('Unknown error') },
                ]);
            }

            setProgress(((i + 1) / selectedEmployers.length) * 100);
        }

        setIsFetching(false);
        setCurrentEmployer(null);
    };

    const downloadCsv = useCallback((base64Csv, employerCode) => {
        try {
            const decodedCsv = atob(base64Csv);
            const blob = new Blob([decodedCsv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `PayrollReport_${employerCode}_${selectedYear}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to decode and save CSV:', error);
        }
    }, [selectedYear]);

    return (
        <Box
            sx={{
                p: 3,
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
                '& .MuiTypography-root': {
                    color: theme.palette.text.primary
                }
            }}
        >
            <Typography
                variant="h4"
                gutterBottom
                sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                }}
            >
                {translate('Reporting')}
            </Typography>

            <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ color: theme.palette.text.secondary }}
            >
                {translate('Select employers, choose a year, and generate payroll reports.')}
            </Typography>

            <FormControl
                sx={{
                    mt: 2,
                    minWidth: 200,
                    '& .MuiInputLabel-root': {
                        color: theme.palette.text.secondary
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.primary.light,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.dark,
                        }
                    }
                }}
            >
                <InputLabel id="year-select-label">{translate('Select Year')}</InputLabel>
                <Select
                    labelId="year-select-label"
                    value={selectedYear}
                    onChange={handleYearChange}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary
                    }}
                >
                    {[...Array(11)].map((_, index) => (
                        <MenuItem
                            key={index}
                            value={currentYear - index + 1}
                            sx={{
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.action.selected
                                }
                            }}
                        >
                            {currentYear - index + 1}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <EmployerCheckboxList email={user} onEmployerSelect={handleEmployerSelect} />

            <Box sx={{ mt: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateReport}
                    disabled={isFetching || selectedEmployers.length === 0}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        '&.Mui-disabled': {
                            backgroundColor: theme.palette.action.disabledBackground,
                            color: theme.palette.action.disabled
                        }
                    }}
                >
                    {isFetching ? translate('Generating Reports...') : translate('Generate Report')}
                </Button>

                {isFetching && (
                    <Box sx={{ mt: 2 }}>
                        <Typography
                            variant="body2"
                            sx={{ color: theme.palette.text.secondary }}
                        >
                            {translate('Processing')} {currentEmployer?.employerName || ''}...
                        </Typography>
                        <CircularProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                mt: 1,
                                color: theme.palette.primary.main
                            }}
                        />
                    </Box>
                )}
            </Box>

            {errors.length > 0 && (
                <Alert
                    severity="error"
                    sx={{
                        mt: 3,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.contrastText,
                        '& .MuiAlert-icon': {
                            color: theme.palette.error.main
                        }
                    }}
                >
                    <Typography variant="subtitle1">
                        {translate('Errors occurred for the following employers:')}
                    </Typography>
                    {errors.map(({ employer, error }, index) => (
                        <Typography
                            key={index}
                            variant="body2"
                            sx={{ color: theme.palette.error.dark }}
                        >
                            {employer}: {error}
                        </Typography>
                    ))}
                </Alert>
            )}
        </Box>
    );
};

export default ReportingPage;