import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useTheme} from '@mui/material';
import { completeAgreeAndSignPage } from '../../reducers/benefitEnrollmentSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import {
    useEnrollWithDependentsMutation,
    useGetCostsPerPayPeriodForLocalPlansQuery, useGetPersonalAndEmploymentDetailsQuery,
    useSubmitApplicationMutation, useUploadEnrollmentSignatureMutation
} from '../../reducers/enrollmentApiSlice';
import useGetEmployerCode from '../../hooks/useGetEmployerCode';
import { useNavigate } from 'react-router-dom';
import useFetchEnrollmentObjectInformationHSA from "../../hooks/useFetchEnrollmentObjectInformationHSA";
import useFetchEnrollmentObjectInformationIchra from "../../hooks/useFetchEnrollmentObjectInformationIchra";
import {removeBase64Header} from "../../utilities/removeBase64Header";
import useFormatEnrollmentDataForLocalPlan from "../../hooks/useFormatEnrollmentDataForLocalPlan";
import AgreeAndSignPageView from "./AgreeAndSignPageView";
import AuthTokenService from "../../services/AuthTokenService";

const AgreeAndSignPage = ({ onNext }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { translate } = useCustomTranslation();
    useTheme();
    const { user } = AuthTokenService.getAuthInfo();
    const selectedPlansWithDependents = useSelector((state) => state.planCoverage.selectedPlansWithDependents);
    const formData = useSelector((state) => state.benefitEnrollment.formData);

    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [signature, setSignature] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState({ title: '', description: '' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const sigCanvas = useRef(null);
    const [enrollWithDependents, { isLoading: isEnrolling }] = useEnrollWithDependentsMutation();

    const selectedPlansExcludingWaivedPlans = selectedPlansWithDependents.filter(plan => plan.planId != null).map(plan => plan.planId);

    const { data: rateCostsPerPayPeriod, error: rateCostsPerPayPeriodError, isLoading: rateCostsPerPayPeriodIsLoading } = useGetCostsPerPayPeriodForLocalPlansQuery({
        planIds: selectedPlansExcludingWaivedPlans,
        email: formData.email,
    }, { skip: selectedPlansExcludingWaivedPlans.length <= 0 });

    const { data: personalDetails, isLoading: isPersonalDetailsLoading, isError: isPersonalDetailsError} = useGetPersonalAndEmploymentDetailsQuery({ email: formData.email });

    const { employerCode, isLoading: isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({
        userEmail: formData.email,
        shouldRedirect: false,
    });

    // HSA
    const {selectedPlanInfo: selectedPlanHsa, selectedDependents: selectedDependentsHsa, waive: waiveHsa} = useSelector(state => state.hsa);
    const {
        enrollmentObject: enrollmentObjectHsa,
        isLoading: isEnrollmentObjectLoadingHsa,
        isError: isEnrollmentObjectErrorHsa,
    } = useFetchEnrollmentObjectInformationHSA({selectedQuote: selectedPlanHsa, selectedDependents: selectedDependentsHsa});
    const {submitApplication: submitApplicationHsa} = useSubmitApplicationMutation();

    // Eb toolkit
    const {selectedPlanInfo: selectedPlanIchra, selectedDependents: selectedDependentsIchra, waive: waiveIchra} = useSelector(state => state.ebToolkit);
    const {enrollmentObject: enrollmentObjectIchra, isLoading: isLoadingIchra, isError: isErrorIchra} = useFetchEnrollmentObjectInformationIchra({selectedPlan: selectedPlanIchra, selectedDependents: selectedDependentsIchra, waiveData: waiveIchra})

    // Benefit Enrollment Signature Endpoints
    const [uploadEnrollmentSignature] = useUploadEnrollmentSignatureMutation();

    useEffect(() => {
        console.log('AgreeAndSignPage: Initial selectedPlansWithDependents', selectedPlansWithDependents);
        console.log('AgreeAndSignPage: Initial formData', formData);
    }, [selectedPlansWithDependents, formData]);

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                const context = canvas.getContext('2d');

                const container = canvas.parentElement;
                const rect = container.getBoundingClientRect();

                const pixelRatio = window.devicePixelRatio || 1;
                canvas.width = rect.width * pixelRatio;
                canvas.height = rect.height * pixelRatio;
                context.scale(pixelRatio, pixelRatio);

                // Restore any existing signature if it exists
                if (signature) {
                    const img = new Image();
                    img.onload = () => {
                        context.drawImage(img, 0, 0);
                    };
                    img.src = '' + signature;
                }
            }
        };

        const debounce = (func, wait) => {
            let timeout;
            return function executedFunction(...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func(...args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        };

        const debouncedResize = debounce(resizeCanvas, 250);

        resizeCanvas(); // Initial resize

        window.addEventListener('resize', debouncedResize);

        return () => {
            window.removeEventListener('resize', debouncedResize);
        };
    }, []); // Empty dependency array so it only runs once


    const handleAgreeChange = (event) => {
        setAgreeToTerms(event.target.checked);
        console.log('Agree to terms changed:', event.target.checked);
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
        console.log('Signature cleared');
    };

    const handleSaveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            const savedSignature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            setSignature(savedSignature);
        }
    };

    // formatEnrollmentData
    const formatEnrollmentData = useFormatEnrollmentDataForLocalPlan(formData.email, employerCode, [formData.email, employerCode]);

    const handleSubmit = async () => {
        if (agreeToTerms && signature) {
            try {
                console.log('Submitting enrollment with plans:', selectedPlansWithDependents);

                // Upload signature first
                const signatureUploadResponse = await uploadEnrollmentSignature({
                    accountEmail: formData.email,
                    signatureData: removeBase64Header(signature ?? '')
                }).unwrap();

                const signatureId = signatureUploadResponse.data;

                // Eb toolkit
                if (enrollmentObjectIchra != null) {
                    console.log("Submitting the Ichra plan.");
                    try {
                        let dto = enrollmentObjectIchra;
                        dto.employeeBenefitEnrollment = {
                            ...dto.employeeBenefitEnrollment,
                            signatureId: signatureId,
                            changedBy: user, // Add the changedBy field here
                        };
                        enrollWithDependents(dto).unwrap();
                    } catch (e) {
                        console.log("Failed to submit Ichra enrollment.");
                    }
                } else if (selectedPlanIchra != null) {
                    console.warn("No Ichra enrollment object found. Failed to submit Ichra enrollment.");
                }

                // Local Enrollment
                await Promise.all(
                    selectedPlansWithDependents.map(plan => {
                        let dto = formatEnrollmentData(plan);
                        dto.employeeBenefitEnrollment = {
                            ...dto.employeeBenefitEnrollment,
                            signatureId: signatureId,
                            changedBy: user, // Add the changedBy field here
                        };
                        return enrollWithDependents(dto).unwrap();
                    })
                );

                dispatch(completeAgreeAndSignPage());
                setSnackbar({
                    open: true,
                    message: translate('Enrollment submitted successfully'),
                    severity: 'success',
                });
                onNext();
                navigate('/home');
            } catch (error) {
                console.error('Enrollment error:', error);
                setModalMessage({
                    title: translate('Submission Error'),
                    description: translate('There was an error submitting your enrollment. Please try again.'),
                });
                setModalOpen(true);
            }
        } else {
            setModalMessage({
                title: translate('Incomplete Submission'),
                description: translate('Please agree to the terms and provide a signature.'),
            });
            setModalOpen(true);
        }
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCloseSnackbar = () => {
        // noinspection JSCheckFunctionSignatures
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const isLoading = isEmployerCodeLoading || isLoadingIchra || isEnrollmentObjectLoadingHsa || rateCostsPerPayPeriodIsLoading || isPersonalDetailsLoading;
    const isError = isEmployerCodeError || isErrorIchra || isEnrollmentObjectErrorHsa || rateCostsPerPayPeriodError || isPersonalDetailsError;

    return (
        <AgreeAndSignPageView

            isLoading={ isLoading }
            isError={ isError }
            rateCostsPerPayPeriod={rateCostsPerPayPeriod}

            selectedPlansWithDependents={selectedPlansWithDependents}
            selectedPlanHsa={selectedPlanHsa} selectedDependentsHsa={selectedDependentsHsa} waiveHsa={waiveHsa}
            selectedPlanIchra={selectedPlanIchra} selectedDependentsIchra={selectedDependentsIchra} waiveIchra={waiveIchra}

            handleSubmit={handleSubmit}
            isEnrolling={isEnrolling}
            modalOpen={modalOpen} modalMessage={modalMessage} handleCloseModal={handleCloseModal}
            snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar}
            sigCanvas={sigCanvas} signature={signature} handleClearSignature={handleClearSignature} handleSaveSignature={handleSaveSignature}
            agreeToTerms={agreeToTerms} handleAgreeChange={handleAgreeChange}
            formatEnrollmentData={formatEnrollmentData}
            personalDetails={personalDetails}
        />
    );
};

export default AgreeAndSignPage;
