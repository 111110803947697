import useCustomTranslation from "../../../hooks/useCustomTranslation";
import {useDispatch} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import AuthTokenService from "../../../services/AuthTokenService";
import {Box, Grid, Paper, TextField, Typography} from "@mui/material";
import {
    useGetDependentsByAccountEmailQuery
} from "../../../reducers/enrollmentApiSlice";
import DependentCoverage from "../../ReviewBenefitPage/DependentCoverage";
import {setWaiveDataHsa} from "../../../reducers/hsaReducer";
import {useGetCostPerPayPeriodHsa} from "../../../hooks/useGetCostPerPayPeriodHsa";

const SelectedPlansHSA = ({selectedPlan, selectedDependents, waive}) => {
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();
    const [dependentsCoverage, setDependentsCoverage] = useState({});

    const authInfo = AuthTokenService.getAuthInfo();
    const userEmail = authInfo.user;

    const { data: dependentsData, isLoading: isDependentsLoading, isError: isDependentsError, error: dependentsError } = useGetDependentsByAccountEmailQuery({ email: userEmail });
    const {
        costPerPayPeriodFormatted,
        annualCostFormatted,
        costPerPayPeriod,
        annualCost,
        isCostPerPayPeriodDataLoading,
        isCostPerPayPeriodDataError,
    } = useGetCostPerPayPeriodHsa(selectedPlan);

    useEffect(() => {
        if(selectedPlan) {
            console.log(`HSA Selected Plan: ${JSON.stringify(selectedPlan)}`);
            console.log(`HSA Selected Dependents: ${JSON.stringify(selectedDependents)}`);
            console.log(`HSA Waive Options: ${JSON.stringify(waive)}`);
        }
    }, [selectedPlan, selectedDependents, waive]);

    // Set setDependentsCoverage when selectedDependents loads. We need this data in a different format for the dependents list.
    useEffect(() => {
        if(selectedDependents) {
            setDependentsCoverage((prev) => {
                let currentlySelectedDependentsObject = {};
                for (let i = 0; i < selectedDependents.length; ++i) {
                    currentlySelectedDependentsObject[+selectedDependents[i]] = {isCovered: true};
                }
                const final = {
                    ...prev,
                    ...currentlySelectedDependentsObject,
                };

                console.log(`Setting selected dependents:`, final);

                return final;
            });
        }
    }, [selectedDependents]);

    // Handle waiver reason changes
    const handleWaiverReasonChange = useCallback((reason) => {
        dispatch(setWaiveDataHsa({
            shouldWaive: true,
            reason: reason,
        }));
        console.log(`Updated waiver reason for hsa plan. Reason:`, reason);
    }, [dispatch]);

    const renderField = useCallback((label, value) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body2" component="dd">{value}</Typography>
            </Grid>
        );
    }, [translate]);

    if (!waive.shouldWaive && selectedPlan == null) {
        return null;
    }

    return (
        <Box component="section" aria-labelledby="selected-plans-title">
                        <Paper key={selectedPlan?.hiosid ?? 'hsa-review-box'} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {selectedPlan?.planDescpt ?? ''}
                                {!waive.shouldWaive && ' (ICHRA)'}
                            </Typography>

                            {waive.shouldWaive ? (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'error.main' }} gutterBottom>
                                        {translate('Plan Waived')}
                                    </Typography>
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waive.reason}
                                        onChange={(e) => handleWaiverReasonChange(e.target.value)}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} component="dl">
                                        {renderField("Description", selectedPlan.planDescpt)}
                                        {renderField("Carrier", selectedPlan.carrierName)}
                                        {renderField("Total Cost Per Year", annualCostFormatted)}
                                        {renderField("Total Cost Per Pay Period", costPerPayPeriodFormatted)}
                                    </Grid>

                                    {dependentsData.length > 0 && (
                                        <DependentCoverage
                                            planId={selectedPlan.hiosid}
                                            dependentsData={dependentsData}
                                            dependentsCoverage={dependentsCoverage}
                                            onDependentChange={() => {}}
                                            disabled={true}
                                        />
                                    )}

                                </>
                              )}
                        </Paper>
        </Box>
    );
};

export default SelectedPlansHSA;
