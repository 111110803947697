import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {useTheme} from "@mui/material/styles";

const IsHsaEligibleDropdown = ({selectedOption = '', onChange}) => {
    useTheme();
    const { translate } = useCustomTranslation();

    return (
        <FormControl sx={{ minWidth: '200px', width: '100%' }}>
            <InputLabel>{translate('Filter by HSA Eligibility')}</InputLabel>
            <Select
                value={selectedOption}
                onChange={onChange}
                label={translate('Filter by HSA Eligibility')}
            >
                <MenuItem key={'Show All'} value={''}>
                    {translate('Show All')}
                </MenuItem>
                <MenuItem key={'Not Eligible'} value={'no'}>
                    {translate('Not Eligible')}
                </MenuItem>
                <MenuItem key={'Eligible'} value={'yes'}>
                    {translate('Eligible')}
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default IsHsaEligibleDropdown;