// Custom hook to clear local storage
const useClearLocalStorage = () => {

    // Function to clear local storage
    const clearLocalStorage = () => {
        localStorage.clear();
    };

    // Call the function to clear local storage
    return () => clearLocalStorage();
};

export default useClearLocalStorage;
