import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Container,
    Paper,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
    useMediaQuery,
    Snackbar,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import LoadingSpinner from "../LoadingSpinner";
import {
    useAddOrUpdateEmploymentDetailsMutation,
    useGetEmploymentDetailsQuery,
    useGetAllEmploymentTypesQuery,
    useGetAllPayPeriodsQuery,
} from '../../reducers/enrollmentApiSlice';
import {
    completeEmploymentDetailsPage,
    updateEmploymentDetailsData
} from "../../reducers/benefitEnrollmentSlice";
import dayjs from "dayjs";
import Branding from '../Branding';
import logoImage from '../../assets/logo/default_logo.png';
import { useTheme } from '@mui/material/styles';
import CompanyDropdown from '../Company/CompanyDropdown'; // Import the CompanyDropdown component

const EmploymentDetailsAdminPage = ({ onNext, userEmail, employerCode }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [employmentDetails, setEmploymentDetails] = useState({
        email: userEmail,
        workArrangement: { id: 0, name: '' },
        payPeriod: { id: 0, name: '' },
        salaryOrRate: '',
        dateOfHire: '',
        jobTitle: '',
        companyId: null,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

    // Remove useGetEmployerCode hook since employerCode is now passed as a prop

    // Queries
    const { data: existingDetails, isLoading: isDetailsLoading, error: detailsError } = useGetEmploymentDetailsQuery({ email: userEmail });
    const { data: workArrangements, isLoading: isArrangementsLoading, error: arrangementsError } = useGetAllEmploymentTypesQuery();
    const { data: payPeriods, isLoading: isPeriodsLoading, error: periodsError } = useGetAllPayPeriodsQuery();

    // Mutations
    const [addOrUpdateEmploymentDetails, { isLoading: isUpdating, error: updateError }] = useAddOrUpdateEmploymentDetailsMutation();

    useEffect(() => {
        if (existingDetails) {
            setEmploymentDetails(prevDetails => ({
                ...prevDetails,
                workArrangement: existingDetails.employmentType || { id: 0, name: '' },
                payPeriod: existingDetails.payPeriod || { id: 0, name: '' },
                salaryOrRate: existingDetails.salaryOrRate || '',
                jobTitle: existingDetails.jobTitle || '',
                dateOfHire: existingDetails.dateOfHire || '',
                companyId: existingDetails?.companyId
            }));
        }
        setIsLoading(isDetailsLoading || isArrangementsLoading || isPeriodsLoading);
    }, [
        existingDetails,
        isDetailsLoading,
        isArrangementsLoading,
        isPeriodsLoading,
    ]);

    useEffect(() => {
        if (detailsError || arrangementsError || periodsError) {
            setAlertMessage(translate('Error loading data. Please try again.'));
            setAlertSeverity('error');
            setSnackbarOpen(true);
        }
    }, [detailsError, arrangementsError, periodsError, translate]);

    useEffect(() => {
        if (updateError) {
            setAlertMessage(translate('An error occurred while updating employment details. Please try again.'));
            setAlertSeverity('error');
            setSnackbarOpen(true);
        }
    }, [updateError, translate]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEmploymentDetails(prevDetails => {
            if (name === 'workArrangement') {
                const selectedArrangement = workArrangements.find(arr => arr.id === Number(value));
                return {
                    ...prevDetails,
                    workArrangement: { id: Number(value), name: selectedArrangement ? selectedArrangement.name : '' }
                };
            } else if (name === 'payPeriod') {
                const selectedPeriod = payPeriods.find(period => period.id === Number(value));
                return {
                    ...prevDetails,
                    payPeriod: { id: Number(value), name: selectedPeriod ? selectedPeriod.name : '' }
                };
            } else if (name === 'salaryOrRate') {
                return {
                    ...prevDetails,
                    [name]: value // Store as string to allow empty input
                };
            }
            return {
                ...prevDetails,
                [name]: value
            };
        });
        setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const handleCompanySelection = (company) => {
        console.log("Selected company:", company); // Debugging log
        setEmploymentDetails( prevDetails => {
                return {
                    ...prevDetails,
                    ['companyId']: company?.companyId ?? null
                };
            }
        );
    };

    const validateForm = () => {
        const errors = {};
        const requiredFields = ['workArrangement', 'payPeriod', 'dateOfHire', 'jobTitle', 'salaryOrRate'];
        requiredFields.forEach(field => {
            if (field === 'workArrangement' || field === 'payPeriod') {
                if (!employmentDetails[field] || employmentDetails[field].id === 0) {
                    errors[field] = translate(`${field} is required`);
                }
            } else if (!employmentDetails[field]) {
                errors[field] = translate(`${field} is required`);
            }
        });
        if (employmentDetails.salaryOrRate && Number(employmentDetails.salaryOrRate) <= 0) {
            errors.salaryOrRate = translate('Salary or rate must be greater than 0');
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setAlertMessage(translate('Please fill in all required fields correctly.'));
            setAlertSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        setHasAttemptedSubmit(true);

        try {
            const employmentSubmissionData = {
                ...employmentDetails,
                employmentType: employmentDetails.workArrangement,
                dateOfHire: dayjs(employmentDetails.dateOfHire).isValid()
                    ? dayjs(employmentDetails.dateOfHire).format('YYYY-MM-DD')
                    : null,
                salaryOrRate: Number(employmentDetails.salaryOrRate),
            };

            await addOrUpdateEmploymentDetails(employmentSubmissionData).unwrap();

            dispatch(updateEmploymentDetailsData(employmentSubmissionData));
            dispatch(completeEmploymentDetailsPage());

            setAlertMessage(translate('Employment details updated successfully.'));
            setAlertSeverity('success');
            setSnackbarOpen(true);

            onNext();
        } catch (error) {
            console.error("Error during submission:", error);
            setAlertMessage(translate('An error occurred while updating employment details. Please try again.'));
            setAlertSeverity('error');
            setSnackbarOpen(true);
            setHasAttemptedSubmit(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (isLoading) {
        console.log("Loading data...");
        return <LoadingSpinner />;
    }

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Branding
                        logoUrl={logoImage}
                        width={isMobile ? "150px" : "250px"}
                        height={isMobile ? "45px" : "75px"}
                    />
                    <Typography variant="h6" gutterBottom textAlign="center" sx={{ mb: 4 }}>
                        {translate('employmentDetails.instructions', { defaultValue: 'Please provide your employment details.' })}
                    </Typography>
                    {alertMessage && (
                        <Alert severity={alertSeverity} sx={{ width: '100%', mb: 2 }}>
                            {alertMessage}
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit} style={{ width: '100%' }} aria-label="Employment Details Form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={!!formErrors.workArrangement}>
                                    <InputLabel id="work-arrangement-label">{translate('Work Arrangement')}</InputLabel>
                                    <Select
                                        labelId="work-arrangement-label"
                                        name="workArrangement"
                                        value={employmentDetails.workArrangement.id}
                                        onChange={handleInputChange}
                                        label={translate('Work Arrangement')}
                                    >
                                        {workArrangements?.map(arrangement => (
                                            <MenuItem key={arrangement.id} value={arrangement.id}>{arrangement.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.workArrangement && (
                                        <Typography color="error" variant="caption">
                                            {formErrors.workArrangement}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={!!formErrors.payPeriod}>
                                    <InputLabel id="pay-period-label">{translate('Pay Period')}</InputLabel>
                                    <Select
                                        labelId="pay-period-label"
                                        name="payPeriod"
                                        value={employmentDetails.payPeriod.id}
                                        onChange={handleInputChange}
                                        label={translate('Pay Period')}
                                    >
                                        {payPeriods?.map(period => (
                                            <MenuItem key={period.id} value={period.id}>{period.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.payPeriod && (
                                        <Typography color="error" variant="caption">
                                            {formErrors.payPeriod}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={translate('Yearly Income')}
                                    name="salaryOrRate"
                                    type="number"
                                    value={employmentDetails.salaryOrRate}
                                    onChange={handleInputChange}
                                    error={!!formErrors.salaryOrRate}
                                    helperText={formErrors.salaryOrRate}
                                    InputProps={{
                                        startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={translate('Hire Date')}
                                    name="dateOfHire"
                                    type="date"
                                    value={employmentDetails.dateOfHire}
                                    onChange={handleInputChange}
                                    error={!!formErrors.dateOfHire}
                                    helperText={formErrors.dateOfHire}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={translate('Job Title')}
                                    name="jobTitle"
                                    value={employmentDetails.jobTitle}
                                    onChange={handleInputChange}
                                    error={!!formErrors.jobTitle}
                                    helperText={formErrors.jobTitle}
                                />
                            </Grid>
                            {/* Company Dropdown Integration */}
                            <Grid item xs={12}>
                                <CompanyDropdown
                                    employerCode={employerCode}
                                    onSelectionChange={handleCompanySelection}
                                    preselectedCompanyId={employmentDetails?.companyId || null}
                                />
                                {formErrors.company && (
                                    <Typography color="error" variant="caption">
                                        {formErrors.company}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isUpdating || hasAttemptedSubmit}
                                aria-label={translate('Submit employment details')}
                            >
                                {(isUpdating) ? <CircularProgress size={24} /> : translate('Submit')}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={alertMessage}
            />
        </Container>
    );
};

export default EmploymentDetailsAdminPage;
