// employerController.js
export const employerEndpoints = (builder) => ({
    createEmployer: builder.mutation({
        query: (createEmployerRequest) => ({
            url: '/api/Employers',
            method: "POST",
            data: createEmployerRequest,
        }),
    }),
    createEmployerAdmin: builder.mutation({
        query: (createEmployerAdminRequest) => ({
            url: '/api/Employers/employer-admin',
            method: "POST",
            data: createEmployerAdminRequest,
        }),
    }),
    getEmployerByCode: builder.query({
        query: ({ employerCode, email }) => ({
            url: `/api/Employers/ByCode/${encodeURIComponent(employerCode)}?email=${encodeURIComponent(email)}`,
            method: "GET",
        }),
    }),
    getAllEmployers: builder.query({
        query: (email) => ({
            url: `/api/Employers?email=${email}`,
            method: "GET",
        }),
        transformResponse: (response) => {
            // When the response status code is a 204.
            if (!response) {
                return [];
            }
            return response;
        }
    }),
    getAddressesByEmployerCode: builder.query({
        query: ({ employerCode, email }) => ({
            url: `/api/Employers/addressbyemployercode/${encodeURIComponent(employerCode)}?email=${encodeURIComponent(email)}`,
            method: "GET",
        }),
    }),
    addOrUpdateEmployerAddress: builder.mutation({
        query: (addressDto) => ({
            url: '/api/Employers/updateemployeraddress',
            method: "POST",
            data: addressDto,
        }),
    }),
    upsertEmployerLogo: builder.mutation({
        query: (logoDto) => ({
            url: '/api/Employers/UpsertEmployerLogo',
            method: "POST",
            data: logoDto,
        }),
    }),
    getEmployerLogoByEmployerCode: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/GetEmployerLogoByEmployerCode/${encodeURIComponent(employerCode)}`,
            method: "GET",
        }),
    }),
    getEmployeesByEmployerCode: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/${encodeURIComponent(employerCode)}/employees`,
            method: "GET",
        }),
    }),
    getEmployerAdminsByCode: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/employer-admins/${encodeURIComponent(employerCode)}`,
            method: "GET",
        }),
    }),
    removeEmployerAdmin: builder.mutation({
        query: (removeEmployerAdminRequest) => ({
            url: '/api/Employers/remove-employer-admin',
            method: "POST",
            data: removeEmployerAdminRequest,
        }),
    }),
    updateEmployer: builder.mutation({
        query: (updateEmployerRequest) => ({
            url: '/api/Employers/update-employer',
            method: "PUT",
            data: updateEmployerRequest,
        }),
    }),
    upsertIchraParentGroup: builder.mutation({
        query: (upsertIchraParentGroupRequest) => ({
            url: '/api/Employers/upsert-ichra-parent-groups',
            method: "POST",
            data: upsertIchraParentGroupRequest,
        }),
    }),
    getEmployerIchraParentGroup: builder.query({
        query: (employerCode) => ({
            url: `/api/Employers/get-employer-ichra-parent-group?employerCode=${encodeURIComponent(employerCode)}`,
            method: "GET",
        }),
        transformResponse: (response) => response ?? null,
    }),
    upsertBenefitRuleAndPayPeriod: builder.mutation({
        query: (upsertBenefitRuleAndPayPeriodRequest) => ({
            url: '/api/Employers/upsert-benefit-rule-and-pay-period',
            method: "POST",
            data: upsertBenefitRuleAndPayPeriodRequest,
        }),
    }),
    updateIsIchraCompany: builder.mutation({
        query: (updateIsIchraCompanyRequest) => ({
            url: '/api/Employers/update-is-ichra-company',
            method: "POST",
            data: updateIsIchraCompanyRequest,
        }),
    }),
    getEmployerCalculatedEffectiveDate: builder.query({
        query: ({ employerCode, email }) => ({
            url: `/api/Employers/get-effective-date-for-employee-from-employer?employerCode=${encodeURIComponent(employerCode)}&email=${encodeURIComponent(email)}`,
            method: "GET",
        }),
        transformResponse: (response) => response ?? null,
    }),
    upsertEmployerIchraContributions: builder.mutation({
        query: (upsertEmployerIchraContributionsRequest) => ({
            url: '/api/Employers/upsert-employer-ichra-contributions',
            method: "POST",
            data: upsertEmployerIchraContributionsRequest,
        }),
    }),
    getIchraAgeBandedEmployerContributionsAsBase64EncodedCsv: builder.query({
        query: ({ employerCode }) => ({
            url: `/api/Employers/get-ichra-age-banded-employer-contributions-as-base64-encoded-csv?employerCode=${encodeURIComponent(employerCode)}`,
            method: "GET",
        }),
        transformResponse: (response) => response ?? null,
    }),

    getIchraAgeBandedEmployerContributionForEmployee: builder.query({
        query: ({ employerCode, userEmail }) => ({
            url: `/api/Employers/get-ichra-age-banded-employer-contribution-for-employee?EmployerCode=${encodeURIComponent(employerCode)}&UserEmail=${encodeURIComponent(userEmail)}`,
            method: "GET",
        }),
        transformResponse: (response) => response ?? null,
    }),
    terminateEmployee: builder.mutation({
        query: (terminateEmployeeRequest) => ({
            url: '/api/Employers/terminate-employee',
            method: "POST",
            data: terminateEmployeeRequest,
        }),
    }),
});