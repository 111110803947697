import {Grid, Paper, Typography, useTheme} from "@mui/material";
import React from "react";
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import {
    useGetCostPerPayPeriodForEmployeeFromRateEbToolKitQuery,
    useGetDependentsByAccountEmailQuery
} from "../../../reducers/enrollmentApiSlice";
import authTokenService from "../../../services/AuthTokenService";
import PlanSelectionRowInfoField from "../../PlanSelection/PlanSelectionRowInfoField";
import {useGetCostPerPayPeriod} from "../../../hooks/useGetCostPerPayPeriod";
import {formatCurrency} from "../../../utilities/formatCurrency";
import LoadingSpinner from "../../LoadingSpinner";
import {useGetCostPerPayPeriodEbToolKit} from "../../../hooks/useGetCostPerPayPeriodEbToolKit";

const SelectedPlanReadOnlyIchra = ({plan, selectedDependents, waiveData}) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    const { user: userEmail } = authTokenService.getAuthInfo();
    const { data: dependentsData, isLoading: isDependentsDetailsLoading, isError: isDependentsDetailsError } = useGetDependentsByAccountEmailQuery({ email: userEmail }, {
        skip: !userEmail
    });

    const {
        monthlyCostFormatted,
        costPerPayPeriodFormatted,
        annualCostFormatted,
        costPerPayPeriod,
        annualCost,
        isCostPerPayPeriodDataLoading,
        isCostPerPayPeriodDataError
    } = useGetCostPerPayPeriodEbToolKit(plan);

    if(isDependentsDetailsLoading || dependentsData == null) {
        return null;
    }

    if (plan == null && !waiveData.shouldWaive) {
        return null;
    }

    const coveredDependents = dependentsData.filter(dependent => selectedDependents.includes(dependent.dependentId.toString())).map(dep => `${dep.person.firstName} ${dep.person.lastName}`).join(', ');

    return (
        <Grid item xs={12} key={'hsa-selected-plan-read-only'}>
            <Paper variant="outlined" sx={{ p: 2, backgroundColor: theme.palette.background.default }}>
                <Typography variant="h6" sx={{ mb: 1, color: theme.palette.primary.main }}>
                    {plan?.planName ?? ''} {waiveData.shouldWaive ? `(${translate('Waived')})` : ''}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    {translate('ICHRA')}
                </Typography>
                {!waiveData.shouldWaive && (
                    <>
                        <Typography variant="body2">
                            <strong>{translate('Carrier')}: </strong>{' '}
                            {plan.issuerName}
                        </Typography>
                        <Typography variant="body2">
                            <strong>{translate('Covered Dependents')}: </strong>{' '}
                            { coveredDependents.length !== 0 ? coveredDependents : 'None' }
                        </Typography>
                        <Typography variant="body2">
                            <strong>{translate('Monthly Premium')}: </strong>{' '}
                            {monthlyCostFormatted}
                        </Typography>
                        <Typography variant="body2">
                            <strong>{translate('Premium Cost Per Pay Period')}: </strong>{' '}
                            {costPerPayPeriodFormatted}
                        </Typography>
                    </>
                )}
                {waiveData.shouldWaive && (
                    <Typography variant="body2">
                        <strong>{translate('Waiver Reason')}:</strong> {waiveData.reason || translate('No reason provided')}
                    </Typography>
                )}
            </Paper>
        </Grid>
    );
};

export default SelectedPlanReadOnlyIchra;