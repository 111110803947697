export const employmentDetailsEndpoints = (builder) => ({
    addOrUpdateEmploymentDetails: builder.mutation({
        query: (employmentDetails) => ({
            url: '/EmployeeDetails/AddOrUpdateEmployeeDetails',
            method: 'POST',
            data: employmentDetails, // Changed from 'data' to 'body'
        }),
        // Disable default error handling to prevent automatic retries
        extraOptions: { maxRetries: 0 },
    }),
    getEmploymentDetails: builder.query({
        query: ({ email }) => {
            if (!email) {
                console.error('Email is missing in getEmploymentDetails query!');
                throw new Error('Email is required');
            }
            return {
                url: `/EmployeeDetails/GetEmployeeDetails?email=${encodeURIComponent(email)}`,
                method: 'GET',
            };
        },
        transformResponse: (response) => {
            return response ?? {};
        },
        onQueryStarted: async (arg, { queryFulfilled }) => {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error('Error in getEmploymentDetails query:', error);
            }
        },
    }),
    getCostsPerPayPeriodForLocalPlans: builder.query({
        query: ({ email, planIds }) => {
            if (!email) {
                console.error('Email is missing in getCostsPerPayPeriodForLocalPlans query!');
                throw new Error('Email is required');
            }

            let planIdsString = planIds.map(planId => `planIds=${planId}`).join('&');

            return {
                url: `/EmployeeDetails/GetCostsPerPayPeriodForLocalPlans?${planIdsString}&userEmail=${encodeURIComponent(email)}`,
                method: 'GET',
            };
        },
        transformResponse: (response) => {
            return response ?? {};
        },
        onQueryStarted: async (arg, { queryFulfilled }) => {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error('Error in getCostsPerPayPeriodForLocalPlans query:', error);
            }
        },
    }),
    getCompaniesByEmployerCode: builder.query({
        query: ({ employerCode }) => {
            if (!employerCode) {
                console.error('Employer code is missing in getCompaniesByEmployerCode query!');
                throw new Error('Employer code is required');
            }
            return {
                url: `/EmployeeDetails/GetCompaniesByEmployerCode?employerCode=${encodeURIComponent(employerCode)}`,
                method: 'GET',
            };
        },
        onQueryStarted: async (arg, { queryFulfilled }) => {
            try {
                await queryFulfilled;
            } catch (error) {
                console.error('Error in getCompaniesByEmployerCode query:', error);
            }
        },
    }),
});