import React, { useState, useEffect } from 'react';
import {Typography, Alert, useTheme} from '@mui/material';
import {
    useGetPersonalAndEmploymentDetailsQuery,
    useGetLocationsQuery,
    useGetEmployerCalculatedEffectiveDateQuery, useGetAddressesQuery
} from '../../../reducers/enrollmentApiSlice';
import authTokenService from '../../../services/AuthTokenService';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import HealthPlanQuoteViewer from './HealthPlanQuoteViewer';
import dayjs from 'dayjs';
import LoadingSpinner from "../../LoadingSpinner";
import useGetPlanSelectionControlsData from "../../../hooks/useGetPlanSelectionControlsData";
import {clearSelectedPlanIchra, setSelectedPlanIchra, setWaiveDataIchra} from "../../../reducers/ebToolkitReducer";
import {useDispatch, useSelector} from "react-redux";
import {PlanSelectionControls} from "../../PlanSelection/PlanSelectionControls";
import {validateDateOfBirth} from "../../../utilities/formatDate";

const EbIchraEnrollment = ({
                               onNext,
                               onPrevious,
                               hasPreviousPlanTypeStep,
                               currentBenefitType,
                               currentBenefitTypeIndex, setCurrentBenefitTypeIndex,
                               planType,
                               employerCode,
                               isIchraCompany,
                               isHsaEmployer
                           }) => {
    const dispatch = useDispatch();

    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const [zipCode, setZipCode] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [plansToCompare, setPlansToCompare] = useState([]);
    const [showPlansToCompareModal, setShowPlansToCompareModal] = useState(false);

    const authInfo = authTokenService.getAuthInfo();
    const userEmail = authInfo.user;
    const waiveReason = useSelector(state => state.ebToolkit.waive.reason);

    const { data: personalDetails, isLoading: isPersonalDetailsLoading, isError: isPersonalDetailsError } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail });
    const { data: calculatedEffectiveDate, isError: calculatedEffectiveDateError, isLoading: calculatedEffectiveDateIsLoading, calculatedEffectiveDateIsSuccess }
        = useGetEmployerCalculatedEffectiveDateQuery({employerCode, email: userEmail}, {
        skip: !employerCode || !userEmail // Don't execute the query until the employerCode and the user email is available.
    });

    // Zip Code Input Data. Move this into a hook. -- START --
    const { data: addressesData, isLoading: isAddressesLoading, isError: isAddressesError } = useGetAddressesQuery({ email: userEmail });
    const { data: locationsData, isLoading: isLocationsLoading, isError: isLocationsError } = useGetLocationsQuery(zipCode, {
        skip: !zipCode,
    });


    useEffect(() => {
        console.log('Component mounted and initial state setup.');
    }, []);

    // Pass location back up to parent component when it changes
    useEffect(() => {
        if (locationsData && locationsData.locationList && locationsData.locationList.length > 0) {
            const firstLocation = locationsData.locationList[0];
            setSelectedLocation(firstLocation);  // Pass the first location to the parent component
            console.log('Setting first location in EbZipCodeInput:', firstLocation);
        }
    }, [locationsData, selectedLocation, selectedLocation]);

    useEffect(() => {
        if (personalDetails && validateDateOfBirth(personalDetails.dateOfBirth) != null) {
            setDateOfBirth(dayjs(personalDetails.dateOfBirth).format('YYYY-MM-DD'));
        }

        if (personalDetails && personalDetails.zipCode) {
            setZipCode(personalDetails.zipCode);
            console.log(`ZIP Code retrieved from personal details: ${personalDetails.zipCode}`);
        } else if (addressesData && addressesData.length > 0) {
            const postalCode = addressesData[0].postalCode.slice(0, 5);
            setZipCode(postalCode);
            console.log(`ZIP Code retrieved from addresses: ${postalCode}`);
        }
    }, [personalDetails, addressesData, zipCode]);
    // Zip Code Input Data. Move this into a hook. -- END --

    const effectiveDate = calculatedEffectiveDate?.data?.calculatedHsaEffectiveDate ?? null;
    const isLoading = isPersonalDetailsLoading || isLocationsLoading || calculatedEffectiveDateIsLoading || isAddressesLoading;
    const hasError = isPersonalDetailsError || isLocationsError || calculatedEffectiveDateError || isAddressesError;

    if (effectiveDate && zipCode && selectedLocation && dateOfBirth) {
        console.log("All conditions met, rendering HealthPlanQuoteViewer with the following values:");
        console.log("selectedEffectiveDate:", effectiveDate);
        console.log("zipCode:", zipCode);
        console.log("selectedLocation:", selectedLocation);
        console.log("dateOfBirth:", dateOfBirth);
    } else {
        console.log("Not all fields are set yet.");
        console.log("selectedEffectiveDate:", effectiveDate);
        console.log("zipCode:", zipCode);
        console.log("selectedLocation:", selectedLocation);
        console.log("dateOfBirth:", dateOfBirth);
    }

    const {
        snackbarMessage, setSnackbarMessage,
        snackbarOpen, setSnackbarOpen,
    } = useGetPlanSelectionControlsData();

    const handleWaive = () => {
        // Clear the selected ebtoolkit plan.
        dispatch(setSelectedPlanIchra(null));
        dispatch(setWaiveDataIchra({
            shouldWaive: true,
            reason: waiveReason,
        }));
        onNext();
    }

    const handleEnroll = (plan) => {
        if(plan == null) {
            dispatch(clearSelectedPlanIchra());
        } else {
            dispatch(setSelectedPlanIchra(plan));
            dispatch(setWaiveDataIchra({
                shouldWaive: false,
                reason: waiveReason,
            }));
            onNext();
        }
    };

    const isSelectedForComparison = (plan) => {
        return plansToCompare.some((p) => p.planId === plan.planId);
    }

    const addToCompareList = (plan) => {
        setPlansToCompare((prev) => {
            const isSelected = prev.some((p) => p.planId === plan.planId);

            if (isSelected) {
                return prev.filter((p) => p.planId !== plan.planId);
            } else if (prev.length < 3) {
                return [...prev, plan];
            } else {
                setSnackbarMessage(translate('You can only compare up to 3 plans at a time.'));
                setSnackbarOpen(true);
                return prev;
            }

        });
    };
    const handleCompare = () => {
        if(plansToCompare.length >= 2) {
            setShowPlansToCompareModal(true);
            setSnackbarMessage(translate('Comparing selected plans'));
            setSnackbarOpen(true);
        } else {
            setSnackbarMessage(translate('At least 2 plans must be selected for comparison.'));
            setSnackbarOpen(true);
        }
    };

    return (
        <PlanSelectionControls
            handleWaive={handleWaive}
            hasPreviousPlanTypeStep={hasPreviousPlanTypeStep}
            moveToPreviousBenefitType={() => onPrevious()}
            handleCompare={handleCompare}
            snackbarMessage={snackbarMessage} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen}
            comparePlans={plansToCompare}
            employerCode={employerCode}
            currentBenefitTypeIndex={currentBenefitTypeIndex}
            currentBenefitType={currentBenefitType}
            planType={planType}>


                    {
                        isLoading && (
                            <LoadingSpinner />
                        )
                    }

                    {
                        !isLoading && hasError && (
                            <Alert
                                severity="error"
                                sx={{
                                    mb: theme.spacing(2),
                                    color: theme.palette.error.main,
                                    backgroundColor: theme.palette.error.light,
                                }}
                            >
                                {translate("Failed to load the EB ICHRA Enrollment Info. Please try again later.")}
                            </Alert>
                        )
                    }

                    {
                        !isLoading && !hasError && (
                            <>
                                {effectiveDate && zipCode && selectedLocation && dateOfBirth ? (
                                    <HealthPlanQuoteViewer
                                        zipCode={zipCode}
                                        dateOfBirth={dateOfBirth}
                                        countyName={selectedLocation.countyName}
                                        effectiveDate={effectiveDate}
                                        handleEnroll={handleEnroll}
                                        handleAddToCompareList={addToCompareList}
                                        showPlansToCompareModal={showPlansToCompareModal}
                                        setShowPlansToCompareModal={setShowPlansToCompareModal}
                                        plansToCompare={plansToCompare}
                                        isSelectedForComparison={isSelectedForComparison}
                                    />
                                ) : (
                                    <Typography>Waiting for required fields to be set before rendering the HealthPlanQuoteViewer...</Typography>
                                )}
                            </>
                        )
                    }
        </PlanSelectionControls>
    );
};

export default EbIchraEnrollment;
