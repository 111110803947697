// Format the DayJS Date before we send the date to our api.
import dayjs from "dayjs";

export function formatDate(date) {
    const parsedDate = validateDateOfBirth(date);

    if (parsedDate === null || parsedDate === undefined) {
        return null;
    }
    const isoDateString = parsedDate.toDate().toISOString();
    // Remove the Time Zone
    return isoDateString.substring(0, isoDateString.indexOf('T'));
}

/**
 * Checks if a date is valid using Day.js and returns a Day.js object if valid, or null if invalid.
 *
 * @param {string|Date|number} dateOfBirth - The date of birth to validate.
 * @returns {dayjs.Dayjs|null} A Day.js object if the date is valid, or null if invalid.
 */
export function validateDateOfBirth(dateOfBirth) {
    return dayjs(dateOfBirth).isValid() ? dayjs(dateOfBirth) : null;
}

export const nowAsIsoStringWithoutTimezone = () => {
    return new Date().toISOString().split('T')[0];
};