import {Box, Button, Chip, Divider, Grid, Stack, Typography, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React, {useState} from "react";
import {useGetLocalPlanSummaryDocuments} from "../../hooks/useGetLocalPlanSummaryDocuments";
import PlanSelectionPaper from "../PlanSelection/PlanSelectionPaper";
import PlanSelectionSummaryButton from "../PlanSelection/PlanSelectionSummaryButton";
import {PlanSummaryModal, PlanSummaryModalWithUrls} from "../Plan/PlanSummaryModal";

export const BenefitEnrollmentView = ({enrollment, isEditable}) => {
    useTheme();
    const { translate } = useCustomTranslation();

    let planType = enrollment?.additionalInfo?.type ?? null;
    const hasPlanId = enrollment?.carrierPlan.planId !== null && enrollment?.carrierPlan.planId !== 0;
    if (hasPlanId) {
        planType = "local";
    }

    const [isOpen, setIsOpen] = useState(false);
    const {isFetching, documents, fetchDocuments} = useGetLocalPlanSummaryDocuments(hasPlanId ? enrollment.carrierPlan.planId : null);

    let planSummaryLink = null;
    switch (planType) {
        case "ebtoolkit": {
            planSummaryLink = enrollment?.additionalInfo?.plan?.urlForBenefitSummaryCoverage ?? null;
            break;
        }
        case "hsa": {
            // FIXME: Include the hsa plan summary link in additionalInfo.
        }
    }

    const formatDate = (dateString) => {
        return dateString ? new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }) : 'N/A';
    };

    return (
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }} key={`enrollment-id-${enrollment.id}`}>
            <PlanSelectionPaper additionalStyles={{ width: '100%' }}>
                <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: '2rem', height: '100%'}}>
                    <Stack spacing={1}>

                        {
                            isEditable ? (  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                >
                                    {translate('Edit')}
                                </Button>
                            </Box>) : null
                        }

                        <Typography variant="subtitle2" fontSize={'large'}>
                            {enrollment?.isWaived ? "Waived" : (enrollment?.carrierPlan?.productName || 'N/A')}
                        </Typography>
                        <Chip
                            label={enrollment?.carrierPlan?.benefitTypeName || 'N/A'}
                            color="primary"
                            size="small"
                            sx={{ width: 'fit-content' }}
                        />
                    </Stack>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center'}}>
                        <Typography fontWeight='medium' variant="body2">
                            {translate('Enrollment Status:')}
                        </Typography>
                        <Chip
                            label={enrollment?.enrollmentStatus || 'N/A'}
                            color={enrollment?.enrollmentStatus === 'pending' ? 'warning' : 'success'}
                            size="small"
                        />
                    </Box>

                    <Box>
                        <Typography fontWeight='medium' variant="body2">
                            {translate('Plan Description:')}
                        </Typography>
                        <Divider sx={{ my: 1, borderBottomWidth: 'thin' }}/>
                        <Typography variant="body2">
                            {enrollment?.carrierPlan?.description || 'N/A'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography fontWeight='medium' variant="body2">
                            {translate('Dependents: ')}
                        </Typography>
                        <Divider sx={{ my: 1, borderBottomWidth: 'thin' }}/>
                        {enrollment?.dependents?.length > 0 ? (
                            <Stack spacing={1}>
                                {enrollment.dependents.map((dependent) => (
                                    <Box key={dependent?.dependentId}>
                                        <Typography variant="subtitle2">
                                            {dependent?.person?.firstName} {dependent?.person?.lastName}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {dependent?.relationshipType || 'Unknown'} • DOB: {formatDate(dependent?.person?.dateOfBirth)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {translate('No Dependents')}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ mt: 'auto' }}>
                        <PlanSelectionSummaryButton onPlanDetailsClick={async () => {
                            setIsOpen(true);
                            if (hasPlanId) {
                                await fetchDocuments();
                            }
                        }} />
                        {planType === "ebtoolkit" ? <PlanSummaryModalWithUrls
                            urls={planSummaryLink != null ? [planSummaryLink] : []}
                            open={isOpen}
                            handleClose={() => {
                                setIsOpen(false);
                            }}
                        /> : null }
                        {planType !== "ebtoolkit" && planType !== "hsa" ? <PlanSummaryModal isFetching={isFetching} open={isOpen} documents={documents} handleClose={() => { setIsOpen(false); }} /> : null }
                    </Box>
                </Box>

            </PlanSelectionPaper>
        </Grid>
    );
};