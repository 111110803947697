import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Typography, Grid, Box, Button, Dialog, DialogContent} from '@mui/material';
import {
    useLazyGetDocumentsByPlanIdQuery,
    useGetBenefitTypesQuery,
    useGetCarrierByPlanIdQuery
} from '../../reducers/enrollmentApiSlice';
import DocumentViewer from '../PlanSelection/DocumentViewer';
import { gowellColors } from "../../themes/palettes/gowellColors";
import FlexibleCarrierLogo from "../Carrier/FlexibleCarrierLogo";
import ReadOnlyEmployerAdminRatesTable from "../RatesTable/ReadOnlyEmployerAdminRatesTable";
import PlanSelectionSummaryButton from "../PlanSelection/PlanSelectionSummaryButton";
import PlanSelectionEnrollButton from "../PlanSelection/PlanSelectionEnrollButton";
import PlanSelectionRowInfoField from "../PlanSelection/PlanSelectionRowInfoField";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import PlanSelectionPlanName from "../PlanSelection/PlanSelectionPlanName";
import PlanSelectionPaper from "../PlanSelection/PlanSelectionPaper";
import PlanSelectionCompareButton from "../PlanSelection/PlanSelectionCompareButton";

const PlanItemWithEnroll = ({ plan, children, onCompareToggle, isSelectedForComparison, hideCompare, handleEnroll, employerCode, isSelected }) => {
    const [open, setOpen] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [carrierName, setCarrierName] = useState('Loading...');
    const [benefitTypeName, setBenefitTypeName] = useState('Loading...');
    const [showRates, setShowRates] = useState(false);

    const [getDocumentsByPlanId, { isLoading, isError, data }] = useLazyGetDocumentsByPlanIdQuery();
    const { data: benefitTypes, isLoading: isBenefitTypesLoading, error: benefitTypesError } = useGetBenefitTypesQuery();
    const { data: carrier, isLoading: isCarrierLoading, error: carrierError } = useGetCarrierByPlanIdQuery(plan.planId);

    const { translate } = useCustomTranslation();

    useEffect(() => {
        console.log('PlanItemWithEnroll: Initial props', {
            plan,
            onCompareToggle: !!onCompareToggle,
            isSelectedForComparison,
            hideCompare,
            handleEnrollClick: !!handleEnroll,
            employerCode
        });
    }, []);

    useEffect(() => {
        if (benefitTypesError) {
            console.error('Error fetching benefit types:', benefitTypesError);
        }
        if (carrierError) {
            console.error('Error fetching carrier:', carrierError);
        }
    }, [benefitTypesError, carrierError]);

    useEffect(() => {
        if (!isCarrierLoading && carrier) {
            setCarrierName(carrier.carrierName);
        } else if (carrierError) {
            console.error('Error fetching carrier:', carrierError);
        }
    }, [carrier, isCarrierLoading, carrierError]);

    useEffect(() => {
        if (!isBenefitTypesLoading && benefitTypes) {
            const benefitType = benefitTypes.find(bt => bt.benefitTypeId === plan.benefitTypeId);
            if (benefitType) {
                setBenefitTypeName(benefitType.typeName);
            } else {
                setBenefitTypeName('Unknown Benefit Type');
            }
        }
    }, [benefitTypes, isBenefitTypesLoading, plan.benefitTypeId]);

    const handlePlanSummaryClick = async () => {
        if (!plan.planId) {
            console.error('planId is undefined or null.');
            return;
        }
        try {
            const result = await getDocumentsByPlanId(plan.planId);
            if (result.data) {
                setDocuments(result.data);
                setOpen(true);
            } else {
                console.error('No data returned for the document.');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setDocuments([]);
    };

    const handleViewRates = () => {
        setShowRates(true);
    };

    const handleCloseRates = () => {
        setShowRates(false);
    };

    const planLogoId = plan?.logoIds && plan?.logoIds.length >= 1 ? plan.logoIds[0].toString() : '';

    const backgroundColor = isSelectedForComparison ? {
        backgroundColor: gowellColors.grey.dark
    } : {};

    return (
        <PlanSelectionPaper
            additionalStyles={{
                border: isSelectedForComparison ? `2px solid ${gowellColors.blue.base}` : 'none',
                ...backgroundColor,
            }}
        >
            <Grid item padding={4} spacing={2} alignItems="center" justifyContent="center">
                {!hideCompare && (
                    <Grid item xs={12} display="flex" justifyContent="center" sx={{ width: '100%' }}>
                        <PlanSelectionCompareButton isSelectedForComparison={isSelectedForComparison} quote={plan} handleAddToCompareList={onCompareToggle} />
                    </Grid>
                )}
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Box display="flex" alignItems="center">
                        <FlexibleCarrierLogo logoId={planLogoId} width={100} height={100} />
                    </Box>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '1rem' }}>
                    <PlanSelectionPlanName planName={plan.productName} />
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>
                    <PlanSelectionRowInfoField label={'Carrier: '} text={carrierName} />
                    <PlanSelectionRowInfoField label={'Benefit Type: '} text={benefitTypeName} />
                </Box>

                <Box align="center">
                    {plan.planId && (
                        <PlanSelectionSummaryButton onPlanDetailsClick={() => handlePlanSummaryClick()} />
                    )}
                    <PlanSelectionEnrollButton isSelected={isSelected} onSelect={() => { handleEnroll(plan) }} />

                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleViewRates}
                        sx={{ mt: 1, textAlign: 'center', backgroundColor: gowellColors.sealitePidgeon.base }}
                        fullWidth
                    >
                        { translate('View Rates') }
                    </Button>
                    {children}
                </Box>
            </Grid>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogContent>
                    {
                        documents.length !== 0 ?
                            <>
                                <Typography variant='h4' gutterBottom>Plan Summary:</Typography>
                                {
                                    documents.map((document, index) => {
                                        return <DocumentViewer key={`${document.documentName}-${index}`} documentData={document.documentData} />;
                                    })
                                }
                            </>
                            :  <Typography variant='h4' gutterBottom>{translate('No plan summaries found.')}</Typography>
                    }
                </DialogContent>
            </Dialog>
            <Dialog open={showRates} onClose={handleCloseRates} fullWidth maxWidth="lg">
                <DialogContent>
                    <Typography variant='h4' gutterBottom>Plan Rates:</Typography>
                    <ReadOnlyEmployerAdminRatesTable plan={plan} employerCode={employerCode} showRateCostsPerPayPeriod={true} />
                </DialogContent>
            </Dialog>
        </PlanSelectionPaper>
    );
};

PlanItemWithEnroll.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        carrierId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        benefitTypeId: PropTypes.number,
        regionIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    children: PropTypes.node,
    onCompareToggle: PropTypes.func.isRequired,
    isSelectedForComparison: PropTypes.bool.isRequired,
    hideCompare: PropTypes.bool.isRequired,
    handleEnroll: PropTypes.func.isRequired,
    employerCode: PropTypes.string.isRequired,
};

export default PlanItemWithEnroll;
