import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    CircularProgress,
    Button
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import EmployerCheckboxList from '../Employer/EmployerCheckboxList';
import EmployeeDataGrid from './EmployeeDataGrid';
import CreateEmployeeModal from './CreateEmployeeModal';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import LoadingSpinner from "../LoadingSpinner";
import useGetEmployerCode from '../../hooks/useGetEmployerCode';
import AuthTokenService from "../../services/AuthTokenService";
import { Policy } from "../../types/policy";
import { Claim } from "../../types/claim";
import Branding from '../Branding';
import logoImage from "../../assets/logo/default_logo.png";

const ManageEmployees = () => {
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();

    // Determine if the user is a Global Admin or Employer Admin
    const isGlobalAdmin = Policy.userHasSufficientPermissions(Policy.GlobalAdminAgencyBroker);
    const isEmployerAdmin = Policy.userHasSufficientPermissions([Claim.EmployerAdmin]);
    const isAuthorized = isGlobalAdmin || isEmployerAdmin;

    // Call useGetEmployerCode only if the user is an Employer Admin
    const { employerCode, isEmployerCodeLoading, isError, error: employerCodeError } = useGetEmployerCode({
        userEmail: user,
        shouldRedirect: false
    });

    const [isCreateEmployeeModalOpen, setIsCreateEmployeeModalOpen] = useState(false);

    useEffect(() => {
        if (employerCodeError) {
            setError(employerCodeError);
        } else if (employerCode && !selectedEmployer) {
            setSelectedEmployer({ employerCode, employerName: user });
        }
    }, [employerCodeError, employerCode, user]);

    const handleEmployerSelectionChange = (employers) => {
        if (employers.length > 0) {
            const selected = employers[0];
            setSelectedEmployer(selected);
            setIsLoading(true);
            setError(null);
            setTimeout(() => {
                setIsLoading(false);
                setError(null);
            }, 1000);
        } else {
            setSelectedEmployer(null);
        }
    };

    const handleSuccess = () => {
        setSelectedEmployer({ ...selectedEmployer });
    };

    const handleCloseCreateEmployeeModal = () => {
        setIsCreateEmployeeModalOpen(false);
    };

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, p: 6 }}>
                <Branding logoUrl={logoImage} width="250px" height="75px" />
                <Typography variant="h4" gutterBottom>
                    {translate('Manage Employees')}
                </Typography>
            </Box>
            <Box sx={{ my: 4 }}>
                {isGlobalAdmin && (
                    <Paper sx={{ p: 2, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    {translate('Please select an employer to view their employees.')}
                                </Typography>
                                <EmployerCheckboxList onEmployerSelect={handleEmployerSelectionChange}  email={user}/>
                            </Grid>
                        </Grid>
                    </Paper>
                )}

                {isAuthorized && selectedEmployer && (
                    <Paper sx={{ p: 2, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CreateEmployeeModal
                                    open={isCreateEmployeeModalOpen}
                                    onClose={handleCloseCreateEmployeeModal}
                                    employerCode={selectedEmployer.employerCode}
                                    translate={translate}
                                    onSuccess={handleSuccess}
                                />
                                <Accordion sx={{ mt: 2 }}>
                                    <AccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography variant="h6">
                                            {translate('Employee Details')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <EmployeeDataGrid
                                            employerCode={selectedEmployer.employerCode}
                                            currentUserEmail={user}
                                            onSuccess={handleSuccess}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Paper>
                )}

                {isLoading && (
                    <Box display="flex" justifyContent="center" mt={4}>
                        <LoadingSpinner />
                    </Box>
                )}
                {error && <Alert severity="error">{error}</Alert>}
                {isEmployerCodeLoading && (
                    <Box display="flex" justifyContent="center" mt={4}>
                        <LoadingSpinner></LoadingSpinner>
                    </Box>
                )}
                {isError && <Alert severity="error">{employerCodeError}</Alert>}
            </Box>
        </Container>
    );
};

export default ManageEmployees;
