import {Dialog, DialogContent, Typography} from "@mui/material";
import DocumentViewer from "../PlanSelection/DocumentViewer";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {useTheme} from "@mui/material/styles";
import LoadingSpinner from "../LoadingSpinner";

export const PlanSummaryModalWithUrls = ({urls, open, handleClose}) => {
    const { translate } = useCustomTranslation();
    useTheme();

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogContent>
                {
                    urls.length !== 0 ?
                        <>
                            <Typography variant='h4' gutterBottom>{translate('Plan Summary:')}</Typography>
                            {
                                urls.map((url, index) => {
                                    return <iframe key={`plan-summary-pdf-${index}`}
                                        title='plan pdf information'
                                        src={url}
                                        style={{height: 'calc(100vh - 200px)', width: '100%', border: 'none'}}
                                    ></iframe>;
                                })
                            }
                        </>
                        : <Typography variant='h4' gutterBottom>{translate('No plan summaries found.')}</Typography>
                }
            </DialogContent>
        </Dialog>
    );
};

export const PlanSummaryModal = ({documents, open, handleClose, isFetching}) => {
    const { translate } = useCustomTranslation();
    useTheme();

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogContent>
                {
                    isFetching ? <LoadingSpinner /> : (documents.length !== 0 ?
                        <>
                            <Typography variant='h4' gutterBottom>{translate('Plan Summary:')}</Typography>
                            {
                                documents.map((document, index) => {
                                    return <DocumentViewer key={`${document.documentName}-${index}`} documentData={document.documentData} />;
                                })
                            }
                        </>
                        :  <Typography variant='h4' gutterBottom>{translate('No plan summaries found.')}</Typography>)
                }
            </DialogContent>
        </Dialog>
    );
};