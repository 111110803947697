import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {useTheme} from "@mui/material/styles";

const MetalDropdown = ({metals, selectedMetalLevel = '', onChange}) => {
    useTheme();
    const { translate } = useCustomTranslation();

    return (
        <FormControl sx={{ minWidth: '200px', width: '100%' }}>
            <InputLabel>{translate('Select Metal Type')}</InputLabel>
            <Select
                value={selectedMetalLevel}
                onChange={onChange}
                label={translate('Select Metal Type')}
            >
                <MenuItem key={'All Metal Levels'} value={''}>
                    {translate('All Metal Levels')}
                </MenuItem>
                {metals.map((metal) => (
                    <MenuItem key={metal} value={metal}>
                        {metal.replace('_', ' ')}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MetalDropdown;