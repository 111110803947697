import {Box, Grid, InputLabel, MenuItem, Paper, Select, Typography, useMediaQuery, useTheme} from "@mui/material";
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import React, {useEffect, useState} from "react";
import QuoteCardHSA from "./HSAModals/QuoteCardHSA";

const SelectedPlanSelect = ({selectedPlan, selectedPlans, onChange, label}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    const handleOnChange = (e) => {
        const newSelectedPlanId = e.target.value;
        const filteredPlans = selectedPlans.filter(plan => plan.hiosid === newSelectedPlanId)
        onChange(filteredPlans[0]);
    };

    const labelId = `${label}-label`;

    return (
        <>
            <InputLabel id={labelId}>{translate(label)}</InputLabel>
            <Select
                labelId={labelId}
                name={label}
                value={selectedPlan.hiosid}
                onChange={(e) => handleOnChange(e)}
                label={translate(label)}
                variant={"filled"}
                sx={{ mb: 2, maxWidth: '100%' }}
            >
                {selectedPlans?.map(plan => (
                    <MenuItem value={plan.hiosid}>{plan.carrierName} {plan.planDescpt}</MenuItem>
                ))}
            </Select>
        </>
    );
}

const PlanComparisonHsa = ({ plans, onPlanSelect, currentlySelectedPlanHiosId }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const isMediumScreenSize = useMediaQuery(theme.breakpoints.down('md'));

    const [planToCompareLeft, setPlanToCompareLeft] = useState(plans[0]);
    const [planToCompareRight, setPlanToCompareRight] = useState(plans[1]);

    useEffect(() => {
        setPlanToCompareLeft(plans[0]);
        setPlanToCompareRight(plans[1]);
    }, [plans]);

    return (
        <Paper sx={{ p: 2, mt: 6, height: '100%', overflow: 'scroll' }}>
            <Typography variant="h3" gutterBottom align="center" sx={{ mt: 2, mb: 4 }}>{translate('Rate Comparison')}</Typography>

            <Grid container sx={{ rowGap: 8 }}>
                <Grid item xs={12} sm={12} md={6} sx={{ minWidth: '320px' }} key={planToCompareLeft.planDescpt + '-left'}>
                    <SelectedPlanSelect
                        selectedPlans={plans}
                        onChange={(leftValue) => {setPlanToCompareLeft(leftValue)}}
                        selectedPlan={planToCompareLeft}
                        label={isMediumScreenSize ? translate('Top') : translate('Left')}
                    />
                    <Box sx={{
                        width: 'min(90%, 600px)',
                        bgcolor: 'background.paper',
                        boxShadow: 2,
                        p: 2,
                    }}>
                        <QuoteCardHSA quote={planToCompareLeft} onSelect={() => {onPlanSelect(planToCompareLeft)}} isSelected={currentlySelectedPlanHiosId === planToCompareLeft.hiosid} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ minWidth: '320px' }} key={planToCompareRight.planDescpt + '-right'}>
                    <SelectedPlanSelect
                        selectedPlans={plans}
                        selectedPlan={planToCompareRight}
                        onChange={(rightValue) => {setPlanToCompareRight(rightValue)}}
                        label={isMediumScreenSize ? translate('Bottom') : translate('Right')}
                    />
                    <Box sx={{
                        width: 'min(90%, 600px)',
                        bgcolor: 'background.paper',
                        boxShadow: 2,
                        p: 2,
                    }}>
                        <QuoteCardHSA quote={planToCompareRight} onSelect={() => {onPlanSelect(planToCompareRight)}} isSelected={currentlySelectedPlanHiosId === planToCompareRight.hiosid} />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PlanComparisonHsa;