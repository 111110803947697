import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, Paper, Button, useMediaQuery, useTheme, Typography, Alert, Stepper, Step, StepLabel } from '@mui/material';
import HappyMan2Image from "../../assets/images/HappyMan2.png";
import EditAccountInformation from "../Employees/EditAccountInformation";
import LoadingSpinner from "../LoadingSpinner";
import { useGetDependentsByAccountEmailQuery, useGetPersonalAndEmploymentDetailsQuery, useGetContactByEmailQuery } from "../../reducers/enrollmentApiSlice";
import EditDependentPage from "./EditDependentPage";
import EditAddressPage from "./EditAddressPage";
import EditUserEnrollment from "./EditUserEnrollment";
import EmploymentDetailsAdminPage from "./EmploymentDetailsAdminPage";
import useGetEmployerCode from "../../hooks/useGetEmployerCode";

const steps = ['Account Information', 'Employment Details', 'Dependent Information', 'Address Information', 'Plan Enrollment'];

const AdminEnrollmentPage = () => {
    const location = useLocation();
    const { selectedEmployee } = location.state || {};
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    if (!selectedEmployee) {
        navigate('/');
        return null;
    }

    const userEmail = selectedEmployee.email;

    // Use the custom hook to get the employer code
    const { employerCode, isEmployerCodeLoading } = useGetEmployerCode({ userEmail });

    // Log the employerCode for debugging
    useEffect(() => {
        console.log("Fetched Employer Code:", employerCode);
    }, [employerCode]);
    const { refetch: refetchDependents } = useGetDependentsByAccountEmailQuery({ email: userEmail });
    const { refetch: refetchPersonalDetails } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail });
    const { refetch: refetchContactDetails } = useGetContactByEmailQuery({ email: userEmail });

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                refetchDependents(),
                refetchPersonalDetails(),
                refetchContactDetails()
            ]);
            setIsLoading(false);
        };

        fetchData();
    }, [refetchDependents, refetchPersonalDetails, refetchContactDetails]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleEnrollmentComplete = () => {
        // Handle enrollment completion logic here
        navigate('/admin-dashboard'); // Adjust this route as needed
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <EditAccountInformation onNext={handleNext} userEmail={userEmail} />;
            case 1:
                return <EmploymentDetailsAdminPage onNext={handleNext} userEmail={userEmail} employerCode={employerCode}   />;
            case 2:
                return <EditDependentPage onNext={handleNext} userEmail={userEmail} employerCode={employerCode} />;
            case 3:
                return <EditAddressPage email={userEmail} onNext={handleNext} componentKey="edit-address-1" />;
            case 4:
                return <EditUserEnrollment userEmail={userEmail} onEnrollmentComplete={handleEnrollmentComplete}  employerCode={employerCode}/>;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundImage: `url(${HappyMan2Image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            zIndex: 0
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 1
            }} />
            <Container component="main" maxWidth="lg" sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 4,
                zIndex: 2,
                position: 'relative',
                boxShadow: 'none',
                border: 'none',
                padding: isMobile ? '1rem' : '2rem'
            }}>
                <Paper sx={{
                    padding: isMobile ? '1rem' : '2rem',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(5px)'
                }}>
                    {errorMessage && (
                        <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
                            <Typography variant="body2">{errorMessage}</Typography>
                        </Alert>
                    )}
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 4 }}>
                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button
                                onClick={handleBack}
                                disabled={activeStep === 0}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            {activeStep === steps.length - 1 ? (
                                <Button
                                    onClick={handleEnrollmentComplete}
                                    variant="contained"
                                    color="primary"
                                >
                                    Complete Enrollment
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleNext}
                                    variant="contained"
                                    color="primary"
                                >
                                    Next
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default AdminEnrollmentPage;