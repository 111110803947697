import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {updateWaiverReason} from "../reducers/benefitEnrollmentSlice";
import {updateSelectedPlansWithDependents} from "../reducers/planCoverageSlice";

const useLocalSelectedPlansData = ({currentlySelectedPlansWithDependents, selectedPlans}) => {

    const waiverReasons = useSelector(state => state.benefitEnrollment.waiverReasons);
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [waivedPlans, setWaivedPlans] = useState({});
    const [coverageAmounts, setCoverageAmounts] = useState({});
    const dispatch = useDispatch();

    // Handle waiver reason changes
    const handleWaiverReasonChange = useCallback((planId, reason) => {
        dispatch(updateWaiverReason({ planId, reason }));
        console.log(`Updated waiver reason for plan ${planId}:`, reason);
    }, [dispatch]);

    const handleDependentChange = (planId, dependent, isCovered) => {
        setDependentsCoverage(prevState => ({
            ...prevState,
            [planId]: {
                ...prevState[planId],
                [dependent.dependentId]: {
                    ...dependent,
                    isCovered
                }
            }
        }));
        console.log(`Updated dependent coverage for plan ${planId}:`, {
            dependentId: dependent.dependentId,
            fullDependentInfo: dependent,
            isCovered
        });
    };

    // Initialize waived plans and coverage amounts based on the selected plans
    useEffect(() => {
        const initialWaivedPlans = {};
        const initialCoverageAmounts = {};

        selectedPlans.forEach(plan => {
            initialWaivedPlans[plan.planId] = plan.decision === 'waive';
            initialCoverageAmounts[plan.planId] = plan.coverageAmount || 0;
        });

        setWaivedPlans(initialWaivedPlans);
        setCoverageAmounts(initialCoverageAmounts);
        console.log("Initial waived plans:", initialWaivedPlans);
        console.log("Initial coverage amounts:", initialCoverageAmounts);
    }, [selectedPlans]);

    // Whenever selectedPlans, dependentsCoverage, waivedPlans, waiverReasons, or coverageAmounts change, update the store
    useEffect(() => {
        let updatedPlans = selectedPlans.map(plan => ({
            ...plan,
            coveredDependents: dependentsCoverage[plan.planId] || {}, // Add full dependent info here
            coverageAmount: coverageAmounts[plan.planId] || 0,       // Add selected coverage amount
            isWaived: waivedPlans[plan.planId] || false,             // Track if the plan is waived
            waiverReason: waiverReasons[plan.planId] || ''           // Add waiver reason if applicable
        }));

        let mergedPlans = [...currentlySelectedPlansWithDependents];
        updatedPlans.forEach(updatedPlan => {
            mergedPlans = mergedPlans.filter(plan => plan.planId !== updatedPlan.planId && plan.benefitTypeId !== updatedPlan.benefitTypeId);
            mergedPlans.push(updatedPlan);
        });

        // Dispatch the updated plans to the redux store
        dispatch(updateSelectedPlansWithDependents(mergedPlans));

        console.log('Updating selected plans with full dependent info:', {
            updatedPlans,
            dependentsCoverage,
            coverageAmounts,
            waivedPlans,
            waiverReasons,
            handleWaiverReasonChange,
            handleDependentChange
        });
    }, [selectedPlans, dependentsCoverage, waivedPlans, waiverReasons, coverageAmounts, dispatch]);

    return {handleWaiverReasonChange, handleDependentChange, waiverReasons, dependentsCoverage, setDependentsCoverage, waivedPlans, coverageAmounts,setCoverageAmounts};
}

export default useLocalSelectedPlansData;