import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetEmployerCodeByEmailQuery } from "../reducers/enrollmentApiSlice";
import { setEmployerCode } from "../reducers/employerCodeSlice";

const useGetEmployerCode = ({ userEmail, shouldRedirect = true }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employerCode = useSelector(state => state.employerCode);

    const {
        data: employerCodeData,
        isLoading: isEmployerCodeLoading,
        isError,
        error,
        meta,
    } = useGetEmployerCodeByEmailQuery(
        { email: userEmail },
        { skip: employerCode !== '' }
    );

    useEffect(() => {
        console.log("meta:", meta);
        console.log("employerCodeData:", employerCodeData);
        console.log("isEmployerCodeLoading:", isEmployerCodeLoading);
        console.log("isError:", isError);
        console.log("error:", error);

        if (employerCodeData === "terminated") {
            console.log("Employer code is terminated");
            navigate('/home');
            return;
        }

        if (!employerCode && employerCodeData && employerCodeData !== '') {
            dispatch(setEmployerCode(employerCodeData));
        } else if (
            !isEmployerCodeLoading &&
            !employerCodeData &&
            employerCode === '' &&
            shouldRedirect
        ) {
            navigate('/new-account');
        }
    }, [
        employerCodeData,
        isEmployerCodeLoading,
        employerCode,
        navigate,
        dispatch,
        isError,
        error,
        shouldRedirect,
        meta,
    ]);

    return { employerCode, isEmployerCodeLoading, isError, error };
};

export default useGetEmployerCode;