import React from 'react';
import {Grid, Typography, Box, useTheme} from '@mui/material';
import Branding from '../../Branding';
import {gowellColors} from "../../../themes/palettes/gowellColors";
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import PlanSelectionRowInfoField from "../../PlanSelection/PlanSelectionRowInfoField";
import PlanSelectionPlanLevelTag from "../../PlanSelection/PlanSelectionPlanLevelTag";
import shortenPlanName from "../../../utilities/shortenPlanName";
import PlanSelectionSummaryButton from "../../PlanSelection/PlanSelectionSummaryButton";
import PlanSelectionEnrollButton from "../../PlanSelection/PlanSelectionEnrollButton";
import PlanSelectionRowInfoFieldLabel from "../../PlanSelection/PlanSelectionRowInfoFieldLabel";
import PlanSelectionPlanName from "../../PlanSelection/PlanSelectionPlanName";
import PlanSelectionPaper from "../../PlanSelection/PlanSelectionPaper";
import PlanSelectionCompareButton from "../../PlanSelection/PlanSelectionCompareButton";

const QuoteRowHSA = ({ quote, isSelected, onSelect, onPlanDetailsClick, isMobile, isTablet,  handleAddToCompareList, isSelectedForComparison }) => {

    const _ = useTheme();
    const { translate } = useCustomTranslation();

    const backgroundColor = isSelectedForComparison ? {
        backgroundColor: gowellColors.grey.dark
    } : {};

    return (
        <PlanSelectionPaper additionalStyles={{
            border: isSelectedForComparison ? `2px solid ${gowellColors.blue.base}` : 'none',
            ...backgroundColor,
        }}>
        <Grid padding={4} item spacing={2} alignItems="center" justifyContent="center">

            <Grid item xs={12} display="flex" justifyContent="center" sx={{ width: '100%' }}>
                <PlanSelectionCompareButton handleAddToCompareList={handleAddToCompareList} quote={quote} isSelectedForComparison={isSelectedForComparison}  />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '1rem' }}>
                <PlanSelectionPlanName planName={quote.planDescpt} />
                <PlanSelectionPlanLevelTag planType={quote.webLevel} />
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>

                <PlanSelectionRowInfoField label={'Carrier: '} text={shortenPlanName(`${quote.carrierName} (${quote.carrierSymbol})`)} />
                <PlanSelectionRowInfoField label={'Annual Deductible: '} text={shortenPlanName(quote.webAnnDed)} />
                <PlanSelectionRowInfoField label={'Out of Pocket Max: '} text={shortenPlanName(quote.webOopMax)} />

                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <PlanSelectionRowInfoFieldLabel text={'Total: '} />
                    <Typography variant="h6">${quote.censusTotal.toFixed(2)}</Typography>
                </Box>

            </Box>

            <Box align="center">
                <PlanSelectionSummaryButton onPlanDetailsClick={() => onPlanDetailsClick(quote)} />
                <PlanSelectionEnrollButton isSelected={isSelected} onSelect={() => onSelect(quote)} />
            </Box>
            </Grid>
        </PlanSelectionPaper>
    );
};

export default QuoteRowHSA;