import {Box, Button, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React from "react";

export const BenefitEnrollmentComponentFilter = ({ validYears, selectedDate, onDateChange }) => {
    useTheme();
    const { translate } = useCustomTranslation();

    let minYear = validYears.length > 0 ? validYears[0] : null;
    let maxYear = validYears.length > 0 ? validYears[validYears.length - 1] : null;

    const resetFilters = () => {
        onDateChange(null);
    };

    return (
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    slotProps={{ field: { clearable: true } }}
                    label="Filter by Year"
                    value={selectedDate}
                    views={['year']}
                    onChange={(newDate) => {
                        onDateChange(newDate);
                    }}
                    shouldDisableYear={(date) => {
                        const year = date.year();
                        return !validYears.includes(year)
                    }}
                    minDate={minYear != null ? dayjs().year(minYear) : dayjs().year(dayjs().year()) /* Default to the current year. */ }
                    maxDate={maxYear != null ? dayjs().year(maxYear) : dayjs().year(dayjs().year()) /* Default to the current year. */ }
                />
            </LocalizationProvider>

            <Button variant="contained" onClick={resetFilters}>{translate('Reset Filters')}</Button>
        </Box>
    );
}
